import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";
import { mapConfig } from "src/app-config";

const config = [
  {
    name: "MapPage",
    rowProps: { noGutters: true },
    moduleComponentName: "MapPage",
    moduleProps: {
      content: {
        title: "Choose a Topic",
        topics: [
          {
            id: "overview",
            title: "Project Overview",
            locationOptions: {
              zoom: 11,
              center: [150.73, -33.8483],
              pitch: 0,
              bearing: 0,
            },
            layerOptions: {
              visible: [
                "planning-wsp-constructionsite",
                "Construction-footprint",
                "construction-labels",
              ],
            },
            content: {
              elementId: "overview",
              hideContent: true,
              layerControls: [
                {
                  ids: ["planning-wsp-constructionsite"],
                  label: "Construction site",
                },
                {
                  ids: ["Construction-footprint", "construction-labels"],
                  label: "Construction footprint",
                },
              ],
            },
          },
          {
            id: "planning",
            title: "Planning and Assessment Process",
            locationOptions: {
              zoom: 11,
              center: [150.73, -33.8483],
              pitch: 0,
              bearing: 0,
            },
            layerOptions: {
              visible: [
                "South-West-Growth-Centre-and-on-off-airport-delineation",
                "South-West-Growth-Centres-Strategic-Assessment",
              ],
            },
            content: {
              elementId: "planning-process",
              hideContent: true,
              layerControls: [
                {
                  ids: [
                    "South-West-Growth-Centre-and-on-off-airport-delineation",
                  ],
                  label: "Planning approval context",
                },
                {
                  ids: ["South-West-Growth-Centres-Strategic-Assessment"],
                  label: "South West Growth Centres Strategic Assessment",
                },
              ],
            },
          },
          {
            id: "transport",
            title: "Transport and Traffic",
            locationOptions: {
              zoom: 11,
              center: [150.73, -33.8483],
              pitch: 0,
              bearing: 0,
            },
            layerOptions: {
              visible: [
                "Bus-interchange-stop-or-layover",
                "Indicative-haulage-routes",
              ],
            },
            content: {
              elementId: "transport",
              hideContent: true,
              layerControls: [
                {
                  ids: ["Indicative-haulage-routes"],
                  label: "Indicative haulage routes",
                },
                {
                  ids: ["Bus-interchange-stop-or-layover"],
                  label:
                    "Relocation of bus interchange stop and/or layover at St Marys",
                },
              ],
            },
          },
          {
            id: "noise",
            title: "Noise and Vibration",
            locationOptions: {
              zoom: 11,
              center: [150.73, -33.8483],
              pitch: 0,
              bearing: 0,
            },
            layerOptions: {
              visible: [
                "Noise-catchment-areas",
                "Noise-catchment-areas-labels",
                "noise-on-air-operation",
                "noise-stabling-impact",
                "noise-off-airport-operation",
                "Construction-noise-vibration",
                "noise-reciever-typical-worst-affected",
                "Buildings-within-minimum-working-distance-for-cosmetic-damage",
                "Construction-footprint",
                "construction-labels",
              ],
            },
            content: {
              elementId: "noise",
              hideContent: true,
              layerControls: [
                {
                  ids: [
                    "Noise-catchment-areas",
                    "Noise-catchment-areas-labels",
                  ],
                  label: "Noise catchment areas",
                },
                {
                  ids: [
                    "noise-on-air-operation",
                    "noise-off-airport-operation",
                    "noise-stabling-impact",
                  ],
                  label: "Operation noise",
                },
                {
                  ids: [
                    "Construction-noise-vibration",
                    "noise-reciever-typical-worst-affected",
                    "Buildings-within-minimum-working-distance-for-cosmetic-damage",
                  ],
                  label: "Construction noise and vibration",
                },
                {
                  ids: ["Construction-footprint", "construction-labels"],
                  label: "Construction footprint",
                },
              ],
            },
          },
          {
            id: "biodiversity",
            title: "Biodiversity",
            locationOptions: {
              zoom: 11,
              center: [150.73, -33.8483],
              pitch: 0,
              bearing: 0,
            },
            layerOptions: {
              visible: [
                "Threatened-Ecological-Communities-BC-Act",
                "Threatened-Ecological-Communities-EPBC",
                "Western-Sydney-International-Stage-1",
                "Construction-footprint",
                "construction-labels",
              ],
            },
            content: {
              elementId: "biodiversity",
              hideContent: true,
              layerControls: [
                {
                  ids: ["Western-Sydney-International-Stage-1"],
                  label:
                    "Western Sydney International Stage 1 Construction Impact Zone",
                },
                {
                  ids: ["Threatened-Ecological-Communities-BC-Act"],
                  label: "Threatened Ecological Communities BC Act",
                },
                {
                  ids: ["Threatened-Ecological-Communities-EPBC"],
                  label: "Threatened Ecological Communities EPBC Act",
                },
                {
                  ids: ["Construction-footprint", "construction-labels"],
                  label: "Construction footprint",
                },
              ],
            },
          },
          {
            id: "heritage",
            title: "Non-Aboriginal Heritage",
            locationOptions: {
              zoom: 11,
              center: [150.73, -33.8483],
              pitch: 0,
              bearing: 0,
            },
            layerOptions: {
              visible: [
                "Section-170-heritage-register",
                "State-heritage-register",
                "Potential-heritage-items",
                "Penrith-LEP-2010",
                "Liverpool-LEP-2008",
                "Construction-footprint",
                "construction-labels",
              ],
            },
            content: {
              elementId: "non-aboriginal-heritage",
              hideContent: true,
              layerControls: [
                {
                  ids: ["Section-170-heritage-register"],
                  label: "Section 170 heritage register",
                },
                {
                  ids: ["State-heritage-register"],
                  label: "State heritage register",
                },
                {
                  ids: ["Potential-heritage-items"],
                  label: "Potential heritage items",
                },
                {
                  ids: ["Penrith-LEP-2010"],
                  label: "Penrith LEP 2010",
                },
                {
                  ids: ["Liverpool-LEP-2008"],
                  label: "Liverpool LEP 2008",
                },
                {
                  ids: ["Construction-footprint", "construction-labels"],
                  label: "Construction footprint",
                },
              ],
            },
          },
          {
            id: "hydrology",
            title: "Flooding and Hydrology",
            locationOptions: {
              zoom: 11,
              center: [150.73, -33.8483],
              pitch: 0,
              bearing: 0,
            },
            layerOptions: {
              visible: ["5AEP-Flooding", "peak-flood-level-impact"],
            },
            content: {
              elementId: "hydrology",
              hideContent: true,
              layerControls: [
                {
                  ids: ["5AEP-Flooding"],
                  label:
                    "5% annual exceedance probability (AEP) - construction",
                },
                {
                  ids: ["peak-flood-level-impact"],
                  label: "1% AEP - operation",
                },
              ],
            },
          },
          {
            id: "visual",
            title: "Landscape and Visual",
            locationOptions: {
              zoom: 11,
              center: [150.73, -33.8483],
              pitch: 0,
              bearing: 0,
            },
            layerOptions: {
              visible: [
                "Character-areas",
                "construction-photo-montage-points",
                "operations-photo-montage-points",
              ],
            },
            content: {
              elementId: "visual",
              hideContent: true,
              layerControls: [
                {
                  ids: ["construction-photo-montage-points"],
                  label: "Construction photomontages",
                },
                {
                  ids: ["operations-photo-montage-points"],
                  label: "Operational photomontages",
                },
                {
                  ids: ["Character-areas"],
                  label: "Landscape character areas",
                },
              ],
            },
          },
        ],
      },
      geocoderConfig: mapConfig.geocoderConfig,
      icons: mapConfig.icons,
      layerControls: mapConfig.layerControls,
      layers: mapConfig.layers.reverse(),
      mapConfig: mapConfig.mapComponentConfig,
      sites: mapConfig.sites,
      popups: mapConfig.popups,
    },
  },
  {
    name: "Map First Time Modal",
    moduleComponentName: "FirstTimeModal",
    moduleProps: { pageName: "interactive-map", targetModalId: "map-help" },
  },
];

const InteractiveMapPage = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default InteractiveMapPage;
